import * as React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Seo from 'gatsby-plugin-wpgraphql-seo'
import Page from "../common/elements/Page.js"

import ContainerWithBg from "../common/helpers/ContainerWithBg.js"



export default function ConsultoriaPage({ data }) {
  return (
    <Page>
      <Seo post={data.wpPage} />
      <Helmet>
        <link rel="preload" as="image" href="../images/bg-politica-privacidade-hero.png"></link>
      </Helmet>
      <ContainerWithBg
        classBgName="bg-pattern bg-pattern-linear-darker bg-image-politica-privacidade-hero"
        className="container-md tac mh-27r line-lenght-large ai-c jc-c dfc c-white p-r z1 feature-image"
      >
        <h1 className="h1-egg mb-15r">Política de Privacidade</h1>        
      </ContainerWithBg>
      <ContainerWithBg classBgName="bg-color-purple-3 pb-4r" className="container">
        <div className="line-lenght-large m-auto">
          <div className="row justify-content-center">
            <div className="the-content" dangerouslySetInnerHTML={{ __html: data.wpPage.content }} />
          </div>
        </div>
      </ContainerWithBg>

    </Page >
  )
}

export const pageQuery = graphql`
  query {
    wpPage(databaseId: {eq: 3}) {
      nodeType
      title
      uri
      content
      seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
              altText
              sourceUrl
              srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
              altText
              sourceUrl
              srcSet
          }
          canonical
          cornerstone
          schema {
              articleType
              pageType
              raw
          }
      }
    }
  }
`